import { serialize } from 'object-to-formdata';

export function GetFormData(data: any) {
  return serialize(data, {
    indices: true,
  });
}

export function startAndEndOfWeek(date?: Date): Date[] {
  // If no date object supplied, use current date
  // Copy date so don't modify supplied date
  var now = date ? new Date(date) : new Date();

  // set time to some convenient value
  now.setHours(0, 0, 0, 0);

  // Get the previous Monday
  var monday = new Date(now);
  monday.setDate(monday.getDate() - monday.getDay() + 1);

  // Get next Sunday
  var sunday = new Date(now);
  sunday.setDate(sunday.getDate() - sunday.getDay() + 7);

  // Return array of date objects
  return [monday, sunday];
}

export function TimeoutFunc(fn: Function, interval: number) {
  var id = setTimeout(fn, interval);
  let cleared = false;
  const clear = function () {
    cleared = true;
    clearTimeout(id);
  };
}

export function getTree<T, K extends keyof T>(
  list: T[],
  parentField: K,
  childField: K,
  childrenField: K
): T[] {
  var map: any = {},
    node: T,
    roots: T[] = [],
    i: number;

  for (i = 0; i < list.length; i += 1) {
    map[list[i][childField]] = i; // initialize the map
    (list[i][childrenField] as unknown as T[]) = <T[]>[]; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node[parentField] && (node[parentField] as unknown as number) !== 0) {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node[parentField]]]
        ? (<T[]>(list[map[node[parentField]]][childrenField] as unknown)).push(
            node
          )
        : null;
    } else {
      roots.push(node);
    }
  }
  return roots;
}

export function base64ToArrayBuffer(base64: string) {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

// Cached Dropdown filter
export const DropdownFilter = 'dropdownFilter';
export const cachedFilter = (): {
  programmes: string | number;
  semester: string | number;
  paper: string | number;
} | null => {
  const storedFilters = localStorage.getItem(DropdownFilter);
  return storedFilters ? JSON.parse(storedFilters) : null;
};
