<aside class="overlay-container">
  <div id="overlay" class="overlay-list-bubble overlay-list-bubble--is-minimized">
    <header class="overlay-bubble-header justify-content-between px-3 py-2">
      <span style="font-weight: 500; font-size: 16px; color: var(--primaryBlue)"
        >Notifications
        <span
          [matBadge]="getBadge()"
          [matBadgeHidden]="getBadge() == 0"
          matBadgeOverlap="false"
        ></span>
      </span>
      <button id="overlay-button" mat-icon-button (click)="closeOverlay()">
        <mat-icon class="material-icons">keyboard_arrow_up</mat-icon>
      </button>
    </header>
    <section
      class="d-block scrollable overlay-list-bubble__content overlay-list-bubble__content--scrollable"
    >
      <mat-tab-group mat-align-tabs="start" class="notif-tab">
        <mat-tab>
          <ng-template mat-tab-label>
            <span
              [matBadge]="getBadge()"
              [matBadgeHidden]="getBadge() == 0"
              matBadgeOverlap="false"
              >All</span
            >
          </ng-template>
          <mat-nav-list>
            <div
              class="item-container position-relative"
              *ngFor="let notif of notifications"
              (click)="clickNotif(notif)"
            >
              <div
                matRipple
                [ngStyle]="{
                  'border-left':
                    notif.Priority > 0 ? '3px solid var(--primaryBlue)' : 'none'
                }"
                class="list-item d-flex align-items-center px-2"
              >
                <img
                  src="Photo/Staff/{{ notif.FromStaffPhoto }}"
                  [alt]="notif.FromStaffPhoto"
                  class="from-staff-photo"
                  (error)="loadDefaultImage($event)"
                />
                <div style="flex-grow: 1">
                  <div class="d-flex justify-content-between">
                    <span class="notif-title user-select-none">
                      {{ notif.Title }}
                    </span>
                    <span
                      class="greyMain-rubik-normal-12 created-on user-select-none"
                    >
                      {{ moment(notif.CreatedOn) }}
                    </span>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span class="notif-desc user-select-none">
                      {{ notif.Description }}
                    </span>
                    <div
                      *ngIf="notif.ReadStatus === 0"
                      class="notif-indicator"
                    ></div>
                  </div>
                </div>
              </div>

              <span class="popup-menu" (click)="$event.stopPropagation()">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  aria-label="icon-button with a menu"
                >
                  <mat-icon style="font-size: 15px">more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="markAsRead(notif)">
                    <span>Mark as Read</span>
                  </button>
                  <button mat-menu-item (click)="deleteNotif(notif)">
                    <span>Delete</span>
                  </button>
                  <!-- <button mat-menu-item>
                    <mat-icon>notifications_off</mat-icon>
                    <span>Disable alerts</span>
                  </button> -->
                </mat-menu>
              </span>
            </div>
          </mat-nav-list>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <span
              [matBadge]="getImpBadge()"
              [matBadgeHidden]="getImpBadge() == 0"
              matBadgeOverlap="false"
              >Important</span
            >
          </ng-template>
          <mat-nav-list>
            <div
              *ngFor="let notif of impNotifications"
              [ngStyle]="{
                'border-left':
                  notif.Priority > 0 ? '3px solid var(--primaryBlue)' : 'none'
              }"
              (click)="clickNotif(notif)"
              class="list-item d-flex align-items-center"
            >
              <img
                src="Photo/Staff/{{ notif.FromStaffPhoto }}"
                [alt]="notif.FromStaffPhoto"
                class="from-staff-photo"
                (error)="loadDefaultImage($event)"
              />
              <div style="flex-grow: 1">
                <div class="d-flex justify-content-between">
                  <span class="notif-title">
                    {{ notif.Title }}
                  </span>
                  <span class="greyMain-rubik-normal-12">
                    {{ moment(notif.CreatedOn) }}
                  </span>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <span class="notif-desc">
                    {{ notif.Description }}
                  </span>
                  <div
                    *ngIf="notif.ReadStatus === 0"
                    class="notif-indicator"
                  ></div>
                </div>
              </div>
            </div>
          </mat-nav-list>
        </mat-tab>
        <mat-tab label="Grouped">
          <div *ngFor="let item of groupedNotif" class="p-2">
            <div class="notif-grouped">
              {{ item[0] }}
            </div>
            <mat-nav-list>
              <div
                *ngFor="let notif of item[1]"
                [ngStyle]="{
                  'border-left':
                    notif.Priority > 0 ? '3px solid var(--primaryBlue)' : 'none'
                }"
                (click)="clickNotif(notif)"
                class="list-item d-flex align-items-center"
              >
                <img
                  src="Photo/Staff/{{ notif.FromStaffPhoto }}"
                  [alt]="notif.FromStaffPhoto"
                  class="from-staff-photo"
                  (error)="loadDefaultImage($event)"
                />
                <div style="flex-grow: 1">
                  <div class="d-flex justify-content-between">
                    <span class="notif-title">
                      {{ notif.Title }}
                    </span>
                    <span class="greyMain-rubik-normal-12">
                      {{ moment(notif.CreatedOn) }}
                    </span>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span class="notif-desc">
                      {{ notif.Description }}
                    </span>
                    <div
                      *ngIf="notif.ReadStatus === 0"
                      class="notif-indicator"
                    ></div>
                  </div>
                </div>
              </div>
            </mat-nav-list>
          </div>
        </mat-tab>
      </mat-tab-group>
    </section>
  </div>
</aside>
