import { Component } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { AuthService } from './shared/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  isLoading = false;
  isLoggedIn: boolean = false;
  isNotifPanelHide: boolean = true;
  constructor(public router: Router, private auth: AuthService) {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.isLoading = true;
        this.isNotifPanelHide = ![
          '/dashboard/home',
          '/approvals/common-requests',
        ].includes(e.url);
      }
      if (
        e instanceof NavigationEnd ||
        e instanceof NavigationCancel ||
        e instanceof NavigationError
      ) {
        this.isLoading = false;
        this.isLoggedIn = this.auth.isLoggedIn;
      }
      var main = document.getElementById('main')!;
      if (!this.isLoggedIn) {
        main.style.marginLeft = '0px';
        main.style.height = '100vh';
      } else {
        main.style.marginLeft = '60px';
        main.style.removeProperty('height');
      }
    });
    window.addEventListener('click', (e) => {
      if (e.target == document.getElementById('backdrop')) {
        if (this.auth.isSideNavPinnedSubject.value) {
          document.querySelector<HTMLElement>('.hover-element')?.remove();
          document
            .querySelector<HTMLElement>('.ham4')
            ?.classList.toggle('active');
          this.auth.pinSideBar();
        }
      }
    });
    this.auth.isSideNavPinned.subscribe((value) => {
      document.getElementById('backdrop')!.style.display =
        value === false ? 'none' : 'block';
    });
  }

  public get isModulesPage(): boolean {
    var main = document.getElementById('main')!;
    var isModulesPage = this.auth.currentGrpValue.ModuleID == 0;
    if (isModulesPage) main.style.marginLeft = '0px';
    else main.style.marginLeft = '60px';
    return this.auth.currentGrpValue.ModuleID == 0;
  }
}
