<div class="dialog-body d-flex flex-column">
  <span class="bodyFont-inter-semiBold-16 pb-3">Change Academic Year</span>
  <mat-form-field appearance="outline" class="mat-form-field col-12 p-0 pr-2">
    <mat-label>Choose Academic Year</mat-label>
    <mat-select name="Staff" id="staff" [formControl]="formControl">
      <mat-option *ngFor="let active of academicYears" [value]="active">
        {{ active.AcademicYear }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="cancel-button">Close</button>
    <button mat-flat-button class="button-fill-blue" [disabled]="isLoading" (click)="changeAcademicYear()">
      <span *appSpinOn="isLoading">Submit</span>
    </button>
  </div>
</div>