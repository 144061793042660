<app-sidenavbar *ngIf="isLoggedIn && !isModulesPage"></app-sidenavbar>
<div id="main">
  <!-- <div class="d-flex"> -->
  <!-- <div *ngIf="!router.url.includes('authentication')" class="resizer" id="resizer"></div> -->
  <!-- <div> -->
  <app-header *ngIf="isLoggedIn"></app-header>
  <app-page-loader *ngIf="isLoading"></app-page-loader>
  <app-notif-panel *ngIf="isLoggedIn && !isNotifPanelHide"></app-notif-panel>
  <router-outlet></router-outlet>
  <!-- </div> -->
</div>
<!-- </div> -->
