import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { CommonApiService } from '../services/common-apis.service';

@Injectable({ providedIn: 'root' })
export class AuthUserGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.isLoggedIn) {
      // check if route is restricted by role
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/authentication/signin'], {});
    return false;
  }
  canActivateform(){

   if(this.auth.isLoggedform){

    return true;
   }

   this.router.navigate(['/authentication/signin'], {});
   return false;

  }


}

@Injectable({ providedIn: 'root' })
export class guardBySidebar implements CanActivate {
  constructor(private router: Router, private auth: CommonApiService) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    try {
      let permissions = await this.auth.GetPermissions(state.url.split('?')[0]);
      if (!permissions.ViewPermission) {
        this.router.navigate(['/authentication/page500'], {
          queryParams: { returnUrl: state.url },
        });
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  }
}



/* Router Query Params Guard */
@Injectable({ providedIn: 'root' })
export class QueryParamGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
   const redirectUrl = route.data['redirectTo'];

    // Check if the query parameter is present
    if (Object.keys(route.queryParams).length > 0) {
      return true; // Allow navigation to the child route
    } else {
      // Redirect to the parent route if the query parameter is not present
      this.router.navigate([redirectUrl]);
      return false;
    }
  }
}