<div
  class="header sticky-top row dflex align-items-center justify-content-between ml-0 mr-0"
>
  <div class="navbar-brand">
    <svg
      class="ham hamRotate ham4 mr-2"
      style="height: 40px; width: 40px"
      [ngStyle]="{
        display: isModulesPage ? 'none' : 'block'
      }"
      viewBox="0 0 100 100"
      width="80"
      (click)="pinSideBar()"
    >
      <path
        class="line top"
        d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20"
      />
      <path class="line middle" d="m 70,50 h -40" />
      <path
        class="line bottom"
        d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20"
      />
    </svg>
    <img
      [src]="user?.InstitutionEmblem"
      alt=""
      width="40"
      height="40"
      class="d-inline-block align-text-center blackAlt-rubik-normal-24 mr-3"
    />
    <span class="grey-rubik-medium-20 institution-name">
      {{ institution?.InstitutionName }}
    </span>
  </div>

  <div class="dflex row ml-0 mr-0">

    <div class="dflex row mt-2">
      <span title="Academic Year" class="font" >
  
        {{ academicYearResponse ? (academicYearResponse.FromDate | date: 'dd/MM/yyyy') : (user?.AcademicFromDate | date: 'dd/MM/yyyy') }}
        -
        {{ academicYearResponse ? (academicYearResponse.ToDate | date: 'dd/MM/yyyy') : (user?.AcademicToDate | date: 'dd/MM/yyyy') }}
  
      </span>
      <div [matMenuTriggerFor]="menus">
        <button mat-button class="drop-down" >
          <mat-icon>arrow_drop_down</mat-icon>
        </button>
        <mat-menu #menus="matMenu">
          <button mat-menu-item (click)="changeAcademicYear()">
            <mat-icon>edit</mat-icon>
            <span>Change Academic Year</span>
          </button>
        </mat-menu>
      </div>
    </div>



    <div class="divider ml-3"></div>

    <div
      class="profile-container dflex row ml-3 align-items-start justify-content-center"
    >
      <div
        class="profile-avatar cursor-pointer"
        routerLink="staff/StaffProfile"
      >
        <img
          style="border-radius: 50%; object-fit: cover"
          [src]="user?.Photopath"
          alt=""
        />
      </div>
      <div
        class="profile ml-3 dflex flex-column align-items-center justify-content-center"
      >
        <div class="dflex row ml-0 mr-0">
          <span class="blackAlt-inter-normal-16">
            {{ user?.Name }}
          </span>
          <div [matMenuTriggerFor]="menu">
            <button mat-button class="drop-down">
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <!-- <button mat-menu-item (click)="changeAcademicYear()">
                <mat-icon>edit</mat-icon>
                <span>Change Academic Year</span>
              </button> -->
              <button mat-menu-item (click)="ModulesChange()">
                <mat-icon>menu</mat-icon>
                <span>Modules</span>
              </button>
              <button mat-menu-item (click)="ChangePassword()">
                <mat-icon>lock</mat-icon>
                <span>Change Password </span>
              </button>
              <button mat-menu-item (click)="logout()">
                <mat-icon>logout</mat-icon>
                <span>Log out</span>
              </button>
            </mat-menu>
          </div>
        </div>

        <div class="greyMain-rubik-normal-14 text-center text-truncate">
          {{ currentUserGroup }}
        </div>
      </div>
    </div>
  </div>
</div>