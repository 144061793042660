import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { from, of, zip } from 'rxjs';
import { groupBy, mergeMap, toArray } from 'rxjs/operators';
import { TransactionRoutes, TransactionType } from '../../constants';
import { NAACTxnNotifications } from '../../interfaces/user';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-notif-panel',
  templateUrl: './notif-panel.component.html',
  styleUrls: ['./notif-panel.component.scss'],
})
export class NotifPanelComponent implements OnInit {
  notifications: NAACTxnNotifications[] = [];
  impNotifications: NAACTxnNotifications[] = [];
  groupedNotif: [string, NAACTxnNotifications[]][] = [];
  photoPath: string = 'Photo/Staff/';

  constructor(private Auth: AuthService, public router: Router) {}

  async loadNotif() {
    this.Auth.notifications.subscribe((e) => {
      this.notifications = e;
      this.impNotifications = this.notifications.filter((e) => e.Priority > 0);
      this.groupedNotif = [];
      from(this.notifications)
        .pipe(
          groupBy(
            (notif) =>
              TransactionType[
                notif.TransactionType as keyof typeof TransactionType
              ] || 'Other',
            (p) => p
          ),
          mergeMap((group) => zip(of(group.key), group.pipe(toArray())))
        )
        .subscribe((e) => this.groupedNotif.push(e));
    });
  }

  ngOnInit(): void {

    this.loadNotif();
  }

  moment(date: Date) {
    return moment(date).fromNow();
  }
  clickNotif(notif: NAACTxnNotifications) {
    console.log(notif);

    this.markAsRead(notif).then(()=> {
      this.router.navigate(
        [
          TransactionRoutes[
            notif.TransactionType as keyof typeof TransactionRoutes
          ],
        ],
        { queryParams: { MasterID: notif.MasterID } }
      );
    })

  }
  closeOverlay() {
    document
      .getElementById('overlay')
      ?.classList.toggle('overlay-list-bubble--is-minimized');
    document.getElementById('overlay-button')?.classList.toggle('rotate-180');
  }
  getImpBadge() {
    return this.impNotifications.filter((e) => e.ReadStatus === 0).length;
  }
  getBadge() {
    return this.notifications.filter((e) => e.ReadStatus === 0).length;
  }
  loadDefaultImage(event: any) {
    event.target.src = 'assets/NoImage.png';
  }
  async markAsRead(notif: NAACTxnNotifications) {
    notif.ReadStatus = 1;
    await this.Auth.UpdateNAACTxnNotifications(notif.NotificationID, 0);
    await this.loadNotif();
  }

  async deleteNotif(notif: NAACTxnNotifications) {
    //remove from array
    this.notifications = this.notifications.filter(
      (e) => e.NotificationID !== notif.NotificationID
    );
    await this.Auth.UpdateNAACTxnNotifications(notif.NotificationID, 1);
    await this.loadNotif();
  }
}
