import { QpLuaLatexControlsModel } from '../aqar/aqar.interface';

// Web Version
export const WEB_VERSION = '1.0.0';

export var isSidenavPinned: boolean = false;
export var isSideNavHovered: boolean = false;
export var IDofApproved = 2;
export var IDofRejected = 3;
export var IDofPending = 1;

export enum TransactionType {
  'NAACCommitteeEvents' = 'Events',
  'NAACLeaveRequest' = 'Leave Request',
  'WorkAdjustment' = 'Work Adjustment',
}
export enum TransactionTypeID {
  'NAACLeaveRequest' = 1,
  'NAACCommitteeEvents' = 2,
  'WorkAdjustment' = 3,
}

export enum TransactionRoutes {
  'NAACCommitteeEvents' = 'approvals/common-requests',
  'WorkAdjustment' = 'timetable/work-requests',
}

export const CardColorPalette: string[] = [
  'linear-gradient(90deg, #0c67f1 0%, #00b8ee 100%)',
  'linear-gradient(90deg, #f8495e 0%,#ff719b 100%)',
  'linear-gradient(90deg, #339d6a 0%,#1ddea0 100%)',
  'linear-gradient(90deg, #4f5ddf 0%,#8995ff 99.99%)',
  'linear-gradient(90deg, #f46b45 0%, #eea849 100%)',
  'linear-gradient(90deg, #E29587 0%, #D66D75 100%)',
  'linear-gradient(90deg, #9733EE 0%, #DA22FF)',
];

export class VoucherType {
  static readonly CR = new VoucherType(
    'CR',
    'Cash Receipt',
    '/accounts/CashReceipt',
    '/accounts/CashReceiptCreateUpdate'
  );
  static readonly CP = new VoucherType(
    'CP',
    'Cash Payment',
    '/accounts/CashPayment',
    '/accounts/CashPaymentCreateUpdate'
  );
  static readonly PC = new VoucherType(
    'PC',
    'Petty Cash',
    '/accounts/PettyCash',
    '/accounts/PettyCashCreateUpdate'
  );
  static readonly BR = new VoucherType(
    'BR',
    'Bank Receipt',
    '/accounts/BankReceipt',
    '/accounts/BankReceiptCreateUpdate'
  );
  static readonly BP = new VoucherType(
    'BP',
    'Bank Payment',
    '/accounts/BankPayment',
    '/accounts/BankPaymentCreateUpdate'
  );
  static readonly BT = new VoucherType(
    'BT',
    'Bank Transfer',
    '/accounts/BankTransfer',
    '/accounts/BankTransferCreateUpdate'
  );
  static readonly PD = new VoucherType(
    'PD',
    'Post Dated Cheque Receipt',
    '/accounts/PostDatedChequeReceipt',
    '/accounts/PostDatedChequeReceiptCreateUpdate'
  );
  static readonly PP = new VoucherType(
    'PP',
    'Post Dated Cheque Payment',
    '/accounts/PostDatedChequePayment',
    '/accounts/PostDatedChequePaymentCreateUpdate'
  );
  static readonly CO = new VoucherType(
    'CO',
    'Contra Voucher',
    '/accounts/ContraVoucher',
    '/accounts/ContraVoucherCreateUpdate'
  );
  static readonly DN = new VoucherType(
    'DN',
    'Debit Note',
    '/accounts/DebitNote',
    '/accounts/DebitNoteCreateUpdate',
    true
  );
  static readonly CN = new VoucherType(
    'CN',
    'Credit Note',
    '/accounts/CreditNote',
    '/accounts/CreditNoteCreateUpdate',
    true
  );
  static readonly JV = new VoucherType(
    'JV',
    'Journal Voucher',
    '/accounts/JournalVoucher',
    '/accounts/JournalVoucherCreateUpdate',
    true
  );
  static readonly FC = new VoucherType(
    'FC',
    'Fee Collection',
    '/fee/fee-collection-list',
    '/fee/fee-collection',
    true
  );
  static readonly FR = new VoucherType(
    'FR',
    'Fee Refund',
    '/fee/fee-refund-list',
    '/fee/fee-refund',
    true
  );
  static readonly FA = new VoucherType(
    'FA',
    'Application Fee Collection',
    '/fee/application-fee-collection-list',
    '/fee/application-fee-collection',
    true
  );
  static readonly MC = new VoucherType(
    'MC',
    'Miscellaneous Fee Collection',
    '/fee/miscellaneous-fee-collection-list',
    '/fee/miscellaneous-fee-collection',
    true
  );
  static readonly AD = new VoucherType(
    'AD',
    'Advance Fee Collection',
    '/fee/advance-fee-collection-list',
    '/fee/advance-fee-collection',
    true
  );

  // private to disallow creating other instances of this type
  private constructor(
    readonly Type: string,
    public readonly Title: string,
    public readonly returnUrl: string,
    public readonly formUrl: string,
    public isMultiLedger: boolean = false
  ) {}

  toString() {
    return this.Type;
  }
}
export const colors = [
  '#FFAE11',
  '#E8AACB',
  '#99CDB5',
  '#B0B6E1',
  '#B4D4FF',
  '#9BCCE9',
];

/* UserGroups */
export const UserGroupName = {
  1: 'Student',
  2: 'Teacher',
  3: 'Parent',
  4: 'Admin',
  5: 'Alumni',
  6: 'Management',
  7: 'Faculty',
} as const;

export const QpLuaLatexControls: Partial<QpLuaLatexControlsModel> = {
  textFormat: {
    bold: '\\textbf{$1}',
    italic: '\\textit{$1}',
    underline: '\\underline{$1}',
    fontSizes: {
      tiny: '\\tiny{#1}',
      scriptsize: '\\scriptsize{#1}',
      small: '\\small{#1}',
      large: '\\large{#1}',
      Large: '\\Large{#1}',
      Huge: '\\Huge{#1}',
      huge: '\\huge{#1}',
      LARGE: '\\LARGE{#1}',
      normalsize: '\\normalsize{#1}',
    },
  },
  symbols: {
    underscore: '\\_',
    ampersand: '\\&',
    exclamation: '\\!',
    question: '\\?',
    curlyBraceLeft: '\\{',
    curlyBraceRight: '\\}',
  },
  alignment: {
    flushLeft: '\\begin{flushleft}#1\\end{flushleft}',
    flushRight: '\\begin{flushright}#1\\end{flushright}',
    center: '\\begin{center}#1\\end{center}',

    justify: '\\begin{justify}#1\\end{justify}',
    raggedLeft: '\\begin{raggedleft}#1\\end{raggedleft}',
    raggedRight: '\\begin{raggedright}#1\\end{raggedright}',
  },
  language: {
    english: '\\begin{english}#1\\end{english}',
    hindi: '\\begin{hindi}#1\\end{hindi}',
    malayalam: '\\begin{malayalam}#1\\end{malayalam}',
  },
  listType: {
    alphabet: ' \\begin{enumerate}[label=\\alph*)]$1\\end{enumerate}',
    number: ' \\begin{enumerate}$1\\end{enumerate}',
    // number: '\\begin{enumerate}[itemindent=1.7em]$1\\end{enumerate}',
    roman: ' \\begin{enumerate}[label=\\roman*)]$1\\end{enumerate}',
    bullet: ' \\begin{itemize}#1\\end{itemize}',
    upperAlphabet: ' \\begin{enumerate}[label=\\Alph*)]$1\\end{enumerate}',
    upperRoman: ' \\begin{enumerate}[label=\\Roman*)]$1\\end{enumerate}',
    item: ' \\item $1 ',
  },
  image: {
    imageStart: '\\includegraphics[',
    imageEnd: ']',
    width: 'width=',
    height: 'height=',
    aspectRadio: 'keepaspectratio',
  },
  table: {
    tableStart: '\\begin{tblr}{',
    tableEnd: '}#1\\end{tblr}',
    tableHeader: {
      columnCenter: '|c',
      columnLeft: '|l',
      columnRight: '|r',
    },
  },
};
