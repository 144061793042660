import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CustomSnack, SnackBarType } from '@components/custom-snack';
import { Observable, fromEvent } from 'rxjs';
import { UserGroupName } from '../../constants';
import { AcademicYearModel, InstitutionMasterModel } from '../../interfaces/college-model-interface';
import { User } from '../../interfaces/user';
import { AuthService } from '../../services/auth.service';
import { ChangeAcademicDialogueComponent } from '../change-academic-dialogue/change-academic-dialogue.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  user: User | null;
  institution: InstitutionMasterModel | null;
  offlineEvent!: Observable<Event>;
  onlineEvent!: Observable<Event>;
  academicYearResponse: AcademicYearModel | null = null; // Initialize with null
  currentUserGroup: string = '';

  constructor(
    private Auth: AuthService,
    public router: Router,
    public dialog: MatDialog,
    public snackBar: CustomSnack
  ) {
    this.user = this.Auth.currentUserValue;
    this.institution = this.Auth.currentInstitutionValue;
    this.user['HOD'] != "" ? this.currentUserGroup += this.user['AdditionalPositions'] : this.user['AdditionalPositions'] != "" ? this.currentUserGroup += this.user['AdditionalPositions'] : this.currentUserGroup += UserGroupName[this.user['GroupID'] as keyof typeof UserGroupName]
  }

  ngOnInit() {

    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.onlineEvent.subscribe((e) => {
      // handle online mode
      this.snackBar.showNotification(SnackBarType.SUCCESS, 'Back Online');
    });

    this.offlineEvent.subscribe((e) => {
      // handle offline mode
      this.snackBar.showNotification(
        SnackBarType.ERROR,
        'Internet Connection Interrupted'
      );
    });

    const storedAcademicYearResponse = localStorage.getItem('academicYearResponse');
    if (storedAcademicYearResponse) {
      this.academicYearResponse = JSON.parse(storedAcademicYearResponse);
    }

  }

  logout() {
    if (this.Auth.isSideNavPinnedSubject.value) this.pinSideBar();
    this.Auth.logout();
  }

  changeAcademicYear() {
    try {
      const dialogRef = this.dialog.open(ChangeAcademicDialogueComponent, {
        width: '30%',
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result && result.selectedAcademicYear && result.additionalData) {
          const selectedAcademicYear = result.selectedAcademicYear;
          // const additionalData = result.additionalData;

          // Do something with the selected academic year and additionalData in HeaderComponent
          // console.log('Additional Data:', additionalData);

          localStorage.setItem('academicYearResponse', JSON.stringify(selectedAcademicYear));

          this.academicYearResponse = selectedAcademicYear;
        }
      });
    } catch (error) {
      console.log('Error opening dialog:', error);
    }
  }

  pinSideBar() {
    document.querySelector<HTMLElement>('.hover-element')?.remove();
    document.querySelector<HTMLElement>('.ham4')?.classList.toggle('active');
    this.Auth.pinSideBar();
  }

  ModulesChange() {
    if (this.Auth.isSideNavPinnedSubject.value) {
      this.pinSideBar();
    }
    this.router.navigateByUrl('/module/module-page').finally(() => {
      this.Auth.updateModuleByModulePage(0);
    });
  }


  ChangePassword() {
    if (this.Auth.isSideNavPinnedSubject.value) {
      this.pinSideBar();
    }
    // this.router.navigate(['/security/password-change']);
    this.router.navigateByUrl('/security/password-change').finally(() => {
      this.Auth.updateModuleByModulePage(0);
    });

  }

  public get isModulesPage(): boolean {
    return this.Auth.currentGrpValue.ModuleID == 0;
  }
}
