import { Component, OnInit } from '@angular/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
@Component({
  selector: 'app-page-loader',
  templateUrl: './loader.html',
  styleUrls: ['./loader.scss'],
})
export class PageLoaderComponent implements OnInit {
  mode: ProgressBarMode = 'indeterminate';
  value = 50;
  bufferValue = 75;
  constructor() {}
  ngOnInit() {}
}
