import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  EntranceExamMaster,
  OnlineEnquiry,
  afterRegLoginDetails,
  tbRegisteredApplicants,
} from 'src/app/student/student-form.interface';
import { isSideNavHovered, isSidenavPinned } from '../constants';
import { EUserGroups } from '../enums/UserGroups';
import { GetFormData } from '../helper-functons';
import { decrypt, encrypt } from '../helpers/encrypt-decrypt';
import {
  AcademicYearModel,
  InstitutionMasterModel,

} from '../interfaces/college-model-interface';
import { UserGrp } from '../interfaces/master-model-interface';
import { sideMenuInfo } from '../interfaces/menu-interface';
import { ModulesCard } from '../interfaces/modules-card-interface';
import { NAACTxnNotifications, User } from '../interfaces/user';
import { ApiHttpClient } from './api-http-client.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public baseUrl: string;
  private loggedIn = false;
  private dataSource = new BehaviorSubject<any>(null);
  currentData = this.dataSource.asObservable();

  connection: HubConnection;
  constructor(
    private http: ApiHttpClient,
    private router: Router,

    @Inject('BASE_URL') base_url: string
  ) {
    this.baseUrl = base_url;

    this.isSideNavPinnedSubject = new BehaviorSubject(isSidenavPinned);
    this.isSideNavPinned = this.isSideNavPinnedSubject.asObservable();

    this.isSideNavHoveredSubject = new BehaviorSubject(isSideNavHovered);
    this.isSideNavHovered = this.isSideNavHoveredSubject.asObservable();

    this.currentUserSubject = new BehaviorSubject(
      decrypt<User>(localStorage.getItem('currentUser'))
    );

    this.studentRegSubject = new BehaviorSubject(
      decrypt<afterRegLoginDetails>(localStorage.getItem('StudentRegUser'))
    );

    this.currentUser = this.currentUserSubject.asObservable();

    this.currentTokenSubject = new BehaviorSubject(
      decrypt<string>(localStorage.getItem('currentUserToken'))
    );
    this.currentToken = this.currentTokenSubject.asObservable();

    this.currentInstitutionSubject = new BehaviorSubject(
      decrypt<InstitutionMasterModel>(
        localStorage.getItem('currentInstitution')
      )
    );
    this.currentInstitution = this.currentInstitutionSubject.asObservable();

    this.notificationsSubject = new BehaviorSubject<NAACTxnNotifications[]>([]);
    this.notifications = this.notificationsSubject.asObservable();

    this.currentGrpSubject = new BehaviorSubject(
      decrypt<UserGrp>(localStorage.getItem('EUserGroups'))
    );
    this.currentGrp = this.currentGrpSubject.asObservable();

    this.connection = new HubConnectionBuilder()
      .configureLogging(LogLevel.Information)
      .withUrl(this.baseUrl + 'notification')
      .build();

    if (this.isLoggedIn) {
      this.ReadNAACTxnNotifications().then((res) => {
        this.notificationsSubject.next(res);
      });
    }
    this.connection.start().catch(function (err: any) {
      return console.error(err.toString());
    });
    this.connection.on(
      'BroadcastNotification',
      (data: NAACTxnNotifications) => {
        if (data.ToID === this.LoginUserID) {
          this.notificationsSubject.next([
            data,
            ...this.notificationsSubject.value,
          ]);
          // Let's check if the browser supports notifications
          if (!('Notification' in window)) {
            alert('This browser does not support desktop notification');
          }

          // Let's check whether notification permissions have already been granted
          else if (Notification.permission === 'granted') {
            // If it's okay let's create a notification
            let notification = new Notification(data.Title, {
              body: data.Description,
            });
          }

          // Otherwise, we need to ask the user for permission
          else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then(function (permission) {
              // If the user accepts, let's create a notification
              if (permission === 'granted') {
                let notification = new Notification(data.Title, {
                  body: data.Description,
                });
              }
            });
          }
        }
      }
    );
  }
  public currentUserSubject: BehaviorSubject<User | null>;
  public studentRegSubject: BehaviorSubject<afterRegLoginDetails | null>;
  public currentUser: Observable<User | null>;
  public currentTokenSubject: BehaviorSubject<any>;
  public currentToken: Observable<any>;
  public currentInstitutionSubject: BehaviorSubject<any>;
  public currentInstitution: Observable<any>;
  public isSideNavPinnedSubject: BehaviorSubject<boolean>;
  public isSideNavPinned: Observable<boolean>;
  public isSideNavHoveredSubject: BehaviorSubject<boolean>;
  public isSideNavHovered: Observable<boolean>;
  public notificationsSubject: BehaviorSubject<NAACTxnNotifications[]>;
  public notifications: Observable<NAACTxnNotifications[]>;
  public currentGrpSubject: BehaviorSubject<any>;
  public currentGrp: Observable<any>;

  public get currentInstitutionValue() {
    return decrypt<InstitutionMasterModel>(
      localStorage.getItem('currentInstitution')
    );
  }

  GetInstitutions() {
    return this.http
      .get<InstitutionMasterModel[]>('InstitutionMaster/Read')
      .toPromise();
  }

  RegisterApplicant(
    tbregModel: tbRegisteredApplicants,
    photo?: File
  ): Promise<number> {
    let formData = GetFormData({ model: tbregModel });

    return this.http
      .post<number>('OnlineApplication/CreateApplicant', formData)
      .toPromise();
  }


  // CreateOnlineEnquiry(
  //   obj: OnlineEnquiry,
  // ): Promise<number> {
  //   let formData = GetFormData({ model: tbregModel });

  //   return this.http
  //     .post<number>('OnlineApplication/CreateApplicant', formData)
  //     .toPromise();
  // }

  CreateOnlineEnquiry (obj: OnlineEnquiry,) {



    const form = GetFormData({
      obj: obj,

    });

    return this.http
      .post<any>('OnlineApplication/CreateOnlineEnquiry', form)
      .toPromise();
  }


  updateData(data: any) {
    this.dataSource.next(data);
  }


  async loginFormApplicant( tbloginModel: any): Promise<afterRegLoginDetails> {




    let AfterReg: afterRegLoginDetails = await this.http
      .post<afterRegLoginDetails>('OnlineApplication/Login', tbloginModel)
      .toPromise();



    if (AfterReg) {
      localStorage.setItem('StudentRegUser', encrypt(AfterReg));
      localStorage.setItem('applicationID', encrypt(AfterReg.ApplicationID.toString()));
      localStorage.setItem('IsPersonalDetailsCompleted', encrypt(AfterReg.IsPersonalDetailsCompleted));
      localStorage.setItem('IsContactDetailsCompleted', encrypt(AfterReg.IsContactDetailsCompleted));
      localStorage.setItem('IsEducationalDetailsCompleted', encrypt(AfterReg.IsEducationalDetailsCompleted));
      localStorage.setItem('IsDocumentUploaded', encrypt(AfterReg.IsDocumentUploaded));
      localStorage.setItem('StudentRegToken', encrypt(AfterReg.Token));

      this.studentRegSubject.next(AfterReg);


    }



    return AfterReg;
  }



  GetSideMenu() {
    return this.http
      .get<sideMenuInfo[]>('Login/ReadSideMenu', {
        params: {
          StaffID: this.currentGrpValue?.StaffID,
          GroupID: this.currentGrpValue?.GroupID,
          UserID: this.currentGrpValue?.UserID,
          ModuleID: this.currentGrpValue?.ModuleID,
        },
      })
      .toPromise();
  }




  // module page service
  GetModulePage() {
    return this.http
      .get<ModulesCard[]>(`Login/GetModules`, {
        params: {
          UserID: this.currentGrpValue?.UserID,
          GroupID: this.currentGrpValue?.GroupID,
        },
      })
      .toPromise();
  }

  get InstitutionID(): number {
    return this.currentInstitutionValue?.InstitutionID ?? 0;
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value!;
  }


  public get currentStudentValue(): afterRegLoginDetails {
    return this.studentRegSubject.value!;
  }

  public get currentGrpValue(): UserGrp {
    return this.currentGrpSubject.value!;
  }

  public get LoginUserID(): number {
    return this.currentUserValue.LoginUserID;
  }

  public get currentUserToken() {
    return this.currentTokenSubject.value;
  }

  public get isLoggedIn() {
    return this.currentTokenSubject.value != null;
  }


  public get isLoggedform() {
    return this.studentRegSubject.value != null;
  }





  async login(data: {}, Institution: InstitutionMasterModel) {
    let formData = GetFormData(data);

    let user: User = await this.http
      .post<User>('Login/Login', formData)
      .toPromise();
    if (user) {
      user.AppPhoto = this.baseUrl + user?.AppPhoto;
      user.Photopath = this.baseUrl + user?.Photopath;

      if (user.LoginErrorStatusID == 0) {
        //Setting UserGroups
        const Groups: UserGrp = {
          GroupID: user.GroupID,
          name: EUserGroups[user.GroupID], //GroupName
          StaffID: user.StaffID,
          UserID: user.UserID,
          ModuleID: user.GroupID == 2 ? 2 : 0,
          BorrowerID: user.BorrowerID ?? 0,
          LibraryID: user.LibraryID ?? 0,
          BorrowerNo: user.BorrowerNo ?? '',
        };
        localStorage.setItem('currentUserToken', encrypt(user.Token));
        localStorage.setItem('currentUser', encrypt(user));
        localStorage.setItem('currentInstitution', encrypt(Institution));
        localStorage.setItem('EUserGroups', encrypt(Groups));
        this.currentUserSubject.next(user);
        this.currentTokenSubject.next(user.Token);
        this.currentInstitutionSubject.next(Institution);
        this.currentGrpSubject.next(Groups);
        this.ReadNAACTxnNotifications().then((res) => {
          this.notificationsSubject.next(res);
        });
      }
    }
    return user;
  }
  //NAACTxnNotifications/Read(int LoginUserID)
  ReadNAACTxnNotifications() {
    return this.http
      .get<any[]>('NAACNotification/Read', {
        params: {
          LoginUserID: this.currentUserValue?.LoginUserID,
        },
      })
      .toPromise();
  }

  //NAACTxnNotifications/UpdateReadStatus(int NotificationID,int IsDeleted)
  UpdateNAACTxnNotifications(NotificationID: number, IsDeleted: number) {
    const form = GetFormData({
      NotificationID,
      IsDeleted,
    });
    return this.http
      .post<any[]>('NAACTxnNotifications/UpdateReadStatus', form)
      .toPromise();
  }

  changeAcademicYear(AcademicYear: AcademicYearModel) {
    return this.currentUserSubject.pipe(
      map((val) => {
        if (val) {
          val.AcademicYearID = AcademicYear.AcademicYearID;
          val.AcademicYear = AcademicYear.AcademicYear;
          val.AcademicFromDate = AcademicYear.FromDate;
          val.AcademicToDate = AcademicYear.ToDate;
        }
        localStorage.setItem('currentUser', encrypt(val));
        return val;
      })
    );
  }
  logout() {
    // this.sideMenuSubject.next(initial_permissions)
    this.currentUserSubject.next(null);
    this.currentTokenSubject.next(null);
    this.currentInstitutionSubject.next(null);
    localStorage.clear();

    const returnUrl = this.router.url.split('?')[0];
    this.router.navigate([
      '/authentication/signin',
      // { url: returnUrl, ModuleID: this.currentGrpSubject.value.ModuleID },
    ]);
  }


  logoutForm() {
    // this.sideMenuSubject.next(initial_permissions)
    this.currentUserSubject.next(null);
    this.currentTokenSubject.next(null);
    this.studentRegSubject.next(null);

    localStorage.clear();

    const returnUrl = this.router.url.split('?')[0];
    this.router.navigate([
      '/authentication/Registration-Login',

    ]);


  }


  pinSideBar() {
    this.isSideNavPinnedSubject.next(!this.isSideNavPinnedSubject.value);
    document.querySelector('.sidenav')?.classList.toggle('pinned');
    document.querySelector('#main')?.classList.toggle('main-pinned');
  }
  hoverSideBar(isHovered: boolean) {
    this.isSideNavHoveredSubject.next(isHovered);
  }

  updateModuleByModulePage(ModuleID: number) {
    const Groups: UserGrp = {
      GroupID: this.currentGrpValue.GroupID,
      name: EUserGroups[this.currentGrpValue.GroupID], //GroupName
      StaffID: this.currentGrpValue.StaffID,
      UserID: this.currentGrpValue.UserID,
      ModuleID: ModuleID,
    };

    localStorage.setItem('EUserGroups', encrypt(Groups));
    this.currentGrpSubject.next(Groups);
    return Groups;
  }

  ChangePasswordForUser(data: any) {
    const formdata = new FormData();
    formdata.set('UserID', data['UserID'] ?? this.currentUserValue.UserID);
    formdata.set('OldPassword', data['OldPassword'] ?? '');
    formdata.set('NewPassword', data['NewPassword']);
    formdata.set('GroupID', data['GroupID'] ?? this.currentUserValue.GroupID);
    return this.http.post('Users/ChangePassword', formdata).toPromise();
  }




  // ForgotPasswordForApplicant(data: any) {
  //   const formdata = new FormData();
  //   formdata.set('UserID', data['UserID'] ?? this.currentUserValue.UserID);
  //   formdata.set('OldPassword', data['OldPassword'] ?? '');

  //   return this.http.post('Users/ForgotPassword', formdata).toPromise();
  // }



  // ForgotPasswordForApplicant(
  //   EntranceExamID: number,
  //   UserID: string,
  // ): Promise<number> {
  //   let formData = GetFormData({ model: tbregModel });

  //   return this.http
  //     .post<number>('OnlineApplication/CreateApplicant', formData)
  //     .toPromise();
  // }

  // async ForgotPasswordForApplicant(EntranceExamID: number, UserID: string, ): Promise<any> {
  //    let forgotpass : any = await this.http
  //   .post<any>('OnlineApplication/ForgotPassword', {






  //     })

  // return forgotpass;

  // }

  ReadCourse_OnlineEnquiry( InstitutionID :number, CourseCategoryID : number) {
    return this.http
      .get<any>('OnlineApplication/ReadCourse_OnlineEnquiry', {
        params: {

           InstitutionID : InstitutionID,
           CourseCategoryID : CourseCategoryID,

        },
      })
      .toPromise();
  }


  ForgotPasswordForApplicant(
    EntranceExamID: number,
    UserID: string
  ) {
    return this.http
      .get<number>('OnlineApplication/ForgotPassword', {
        params: {
          EntranceExamID: EntranceExamID,
          UserID:UserID
        },
      })
      .toPromise();
  }


  ChangePassword(
    ApplicationID: number,
    OldPassword: string,
    NewPassword: string,
  ) {
    return this.http
      .get<number>('OnlineApplication/ChangePassword', {
        params: {
          ApplicationID: ApplicationID,
          OldPassword : OldPassword,
          NewPassword : NewPassword
        },
      })
      .toPromise();
  }

  ReadProspectus(InstitutionID: number) {
    return this.http
      .get<number>('OnlineApplication/ReadProspectus', {
        params: {
          InstitutionID: InstitutionID
        },
      })
      .toPromise();
  }

}

