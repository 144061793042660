// import * as CryptoJS from 'crypto-js';

const security_key = 'dcfilyebfucveqaycd6d788e36e65ev3';
export function encrypt(data: any) {
  let str_data = JSON.stringify(data);
  str_data = btoa(str_data);
  return str_data;
}

export function decrypt<Type>(str_data: any): Type | null {
  var data = '';
  try {
    data = atob(str_data);
  } catch (error) {
    ('error');
    localStorage.clear();
    window.location.reload();
  }

  return str_data ? JSON.parse(data) : null;
}
