import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { decrypt } from '../helpers/encrypt-decrypt';

@Injectable({
  providedIn: 'root',
})
export class ApiHttpClient extends HttpClient implements HttpInterceptor {
  public baseUrl: string | undefined;
  token: string | null = null;

  public constructor(
    handler: HttpHandler,
    private router: Router,
    @Inject('BASE_URL') base_url: string
  ) {
    super(handler);
    this.baseUrl = base_url;
  }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isStudent: string | null = decrypt(
      localStorage.getItem('StudentRegToken')
    );
    this.token = isStudent ?? decrypt(localStorage.getItem('currentUserToken'));

    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.token}`,
      },
    });
    return next.handle(request).pipe(
      catchError((err) => {
        if ([401, 403].indexOf(err.status) !== -1) {
          // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
          localStorage.clear();
          this.router.navigateByUrl('/authentication/signin');
        }
        const error = err;
        return throwError(error);
      })
    );
  }

  public override get<T>(url: string, options: any = {}): Observable<any> {
    url = this.baseUrl + url;
    options.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // Authorization: `Bearer ${this.token}`,
    });
    var resp = super.get(url, options);

    // resp.subscribe((e) => {
    //   if (!environment.production) {
    //     (e);
    //     return resp;
    //   }
    // });
    return resp;
  }

  public override post<T>(
    url: string,
    body: any = {},
    options?: any
  ): Observable<any> {
    url = this.baseUrl + url;
    var resp = super.post(url, body, {
      headers: new HttpHeaders({
        // Authorization: `Bearer ${this.token}`,
      }),
      observe: 'body',
      params: options?.params,
    });
    // resp.subscribe((e) => {
    //   if (!environment.production) {
    //     (e);
    //     return resp;
    //   }
    // });
    return resp;
  }
}
