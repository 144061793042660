import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AcademicYearModel } from '../../interfaces/college-model-interface';
import { AuthService } from '../../services/auth.service';
import { CommonApiService } from '../../services/common-apis.service';
import { CustomSnack, SnackBarType } from '../custom-snack';

@Component({
  selector: 'app-change-academic-dialogue',
  templateUrl: './change-academic-dialogue.component.html',
  styleUrls: [
    './change-academic-dialogue.component.scss',
    './../../layout/custom-material.scss',
  ],
})
export class ChangeAcademicDialogueComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ChangeAcademicDialogueComponent>,
    public CommonAPI: CommonApiService,
    public Auth: AuthService,
    private _snackBar: CustomSnack,
    private router: Router
  ) { }

  academicYears: AcademicYearModel[] = [];
  formControl: UntypedFormControl = new UntypedFormControl(
    null,
    Validators.required
  );
  isLoading = false;
  async ngOnInit() {
    this.academicYears = await this.CommonAPI.ReadAcademicYear();

    this.formControl.setValue(
      this.academicYears[
      this.academicYears.findIndex(
        (e) => e.AcademicYearID == this.Auth.currentUserValue.AcademicYearID
      )
      ]
    );

    // let c = this.academicYears[
    //   this.academicYears.findIndex(
    //     (e) => e.FromDate == this.Auth.currentUserValue.AcademicFromDate && e.ToDate == this.Auth.currentUserValue.AcademicToDate
    //   )
    // ]






  }
  changeAcademicYear() {
    try {
      this.isLoading = true;
      let c: AcademicYearModel; // Declare c here

      if (this.formControl.valid) {
        c = this.academicYears[
          this.academicYears.findIndex(
            (e) =>
              e.FromDate == this.Auth.currentUserValue.AcademicFromDate &&
              e.ToDate == this.Auth.currentUserValue.AcademicToDate
          )
        ];

        this.Auth.changeAcademicYear(this.formControl.value).subscribe(
          (e) => {
            this._snackBar.showNotification(
              SnackBarType.SUCCESS,
              'Academic Year changed successfully'
            );

            this.dialogRef.close({
              selectedAcademicYear: this.formControl.value,
              additionalData: c,
            });

            let previousUrl = this.router.url.split('?')[0];
            this.router
              .navigateByUrl('authentication', {
                skipLocationChange: true,
              })
              .then(() => this.router.navigateByUrl(previousUrl));
          },
          (e) => {
            this._snackBar.showNotification(
              SnackBarType.ERROR,
              'Something went wrong. Please try again later.'
            );
          }
        );
      }
    } catch (error) {
      this.isLoading = false;
      this._snackBar.showNotification(
        SnackBarType.ERROR,
        'Something went wrong. Please try again later.'
      );
    } finally {
      this.isLoading = false; // Make sure to reset isLoading in all cases
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }



}
