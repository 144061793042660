import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiHttpClient } from '@services/api-http-client.service';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChangeAcademicDialogueComponent } from './shared/components/change-academic-dialogue/change-academic-dialogue.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { PageLoaderComponent } from './shared/components/loader/loader';
import { NotifPanelComponent } from './shared/components/notif-panel/notif-panel.component';
import { SidenavbarComponent } from './shared/components/sidenavbar/sidenavbar.component';
import { SpinnerButtonDirective } from './shared/directives/loading-button.directive';


export function getBaseUrl() {
  // return 'https://' + document.getElementsByTagName('base')[0].href.split(':').slice(1, -1).join(':').concat(':5001/');
  return document.getElementsByTagName('base')[0].href;
  // return 'https://localhost:5001/';
}

export function getReportTemplateUrl() {
  return (
    // 'https://localhost:5001/' +
    document.getElementsByTagName('base')[0].href +
    'api/reports/resources/templates/telerikReportViewerTemplate.html/'
  );
}



export function getMediaUrl() {
  return document.getElementsByTagName('base')[0].href;
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidenavbarComponent,
    NotifPanelComponent,
    PageLoaderComponent,
    ChangeAcademicDialogueComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatIconModule,
    MatMenuModule,
    InlineSVGModule.forRoot(),
    MatTabsModule,
    MatBadgeModule,
    MatListModule,
    MatButtonModule,
    MatSnackBarModule,
    MatRippleModule,
    MatProgressBarModule,
    MatDialogModule,
    MatSelectModule,
    ReactiveFormsModule,
    SpinnerButtonDirective,

  ],
  providers: [
    { provide: 'BASE_URL', useFactory: getBaseUrl },
    { provide: 'MEDIA_URL', useFactory: getMediaUrl },
    { provide: Window, useValue: window },
    { provide: HTTP_INTERCEPTORS, useClass: ApiHttpClient, multi: true },
    { provide: 'reportTemplateUrl', useFactory: getReportTemplateUrl },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
